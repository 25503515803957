/*! Generated by Font Squirrel (https://www.fontsquirrel.com) on November 12, 2024 */



@font-face {
    font-family: 'good_sansblack';
    src: url('./assets/fonts/goodsansblack-webfont.woff2') format('woff2'),
         url('./assets/fonts/goodsansblack-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}




@font-face {
    font-family: 'good_sansbold';
    src: url('./assets/fonts/goodsansbold-webfont.woff2') format('woff2'),
         url('./assets/fonts/goodsansbold-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}




@font-face {
    font-family: 'good_sanslight_italic';
    src: url('./assets/fonts/goodsans-lightitalic-webfont.woff2') format('woff2'),
         url('./assets/fonts/goodsans-lightitalic-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}




@font-face {
    font-family: 'good_sansmedium_italic';
    src: url('./assets/fonts/goodsans-mediumitalic-webfont.woff2') format('woff2'),
         url('./assets/fonts/goodsans-mediumitalic-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}




@font-face {
    font-family: 'good_sansregular';
    src: url('./assets/fonts/goodsansregular-webfont.woff2') format('woff2'),
         url('./assets/fonts/goodsansregular-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}




@font-face {
    font-family: 'good_sansbold_italic';
    src: url('./assets/fonts/goodsans-bolditalic-webfont.woff2') format('woff2'),
         url('./assets/fonts/goodsans-bolditalic-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}




@font-face {
    font-family: 'good_sansheavy';
    src: url('./assets/fonts/goodsans-heavy-webfont.woff2') format('woff2'),
         url('./assets/fonts/goodsans-heavy-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}




@font-face {
    font-family: 'good_sanslight';
    src: url('./assets/fonts/goodsans-light-webfont.woff2') format('woff2'),
         url('./assets/fonts/goodsans-light-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}




@font-face {
    font-family: 'good_sansmedium';
    src: url('./assets/fonts/goodsans-medium-webfont.woff2') format('woff2'),
         url('./assets/fonts/goodsans-medium-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}




@font-face {
    font-family: 'good_sansthin';
    src: url('./assets/fonts/goodsans-thin-webfont.woff2') format('woff2'),
         url('./assets/fonts/goodsans-thin-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}