body{
  font-family: 'good_sansregular';
}
/* ::-webkit-scrollbar {
  width: 4px;
}
::-webkit-scrollbar-track {
  background: #c1c1c98a;
} */
/* ::-webkit-scrollbar {
  width: 6px; 
}
::-webkit-scrollbar-track {
  background: #47474B;
  border-radius: 10px;
}
::-webkit-scrollbar-thumb {
  background: #232326;
  border-radius: 10px;
} */

::-webkit-scrollbar {
  width: 4px;
}
::-webkit-scrollbar-track {
  background: transparent; 
  border-radius: 10px;
}
::-webkit-scrollbar-thumb {
  background: #3c3c3c; 
  border-radius: 10px;
}

